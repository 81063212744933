var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "green-border-box outer-box" }, [
    _c("div", { staticClass: "inner-box" }, [
      _c(
        "span",
        { staticClass: "title", attrs: { title: _vm.offer.offer.name } },
        [_vm._v(_vm._s(_vm.offer.offer.name))]
      ),
      _c("br"),
      _c(
        "span",
        { staticClass: "description", attrs: { title: _vm.offer.offer.price } },
        [
          _vm._v(
            "$" +
              _vm._s(_vm.offer.offer.price) +
              " | " +
              _vm._s(_vm.offer.offer.credits) +
              " Tasks"
          )
        ]
      ),
      _c("span", {
        staticClass: "description",
        attrs: { title: _vm.offer.offer.description },
        domProps: { innerHTML: _vm._s(_vm.offer.offer.description) }
      }),
      _c(
        "span",
        { staticClass: "description", attrs: { title: _vm.offer.offer.price } },
        [
          _vm._v(
            "Must redeem by:  " +
              _vm._s(new Date(_vm.offer.expire_date).toDateString())
          )
        ]
      ),
      _c("br"),
      _vm.offer.is_redeemed == 0
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn-positive",
                attrs: {
                  "data-target": "#redeem-offer-popup",
                  "data-toggle": "modal",
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.redeemOffer(_vm.offer)
                  }
                }
              },
              [_vm._v("REDEEM NOW")]
            )
          ])
        : _c("div", { staticClass: "text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn-negative",
                attrs: { type: "button", disabled: "disabled" }
              },
              [_vm._v("ALREADY REDEEMED")]
            )
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }