<template>
  <div class="green-border-box outer-box">
    <div class="inner-box">
        <span :title="offer.offer.name" class="title">{{offer.offer.name}}</span>
        <br>
        <span class="description" :title="offer.offer.price">${{offer.offer.price}} | {{offer.offer.credits}} Tasks</span>
        <span class="description" :title="offer.offer.description" v-html="offer.offer.description"></span>
        <span class="description" :title="offer.offer.price">Must redeem by:  {{new Date(offer.expire_date).toDateString()}}</span>
      <br>
      <div class="text-center" v-if="offer.is_redeemed == 0">
        <button
          data-target="#redeem-offer-popup"
          data-toggle="modal"
          class="btn-positive"
          type="button"
          @click="redeemOffer(offer)"
        >REDEEM NOW</button>
      </div>
      <div class="text-center" v-else>
        <button
          class="btn-negative"
          type="button"
          disabled="disabled"
        >ALREADY REDEEMED</button>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  props: ['offer'],
  methods: {
    setOfferIdToDelete (offerId) {
      this.$emit('setOfferIdToDelete', offerId)
    },

    redeemOffer (offer) {
      this.$emit('setActiveBrandOffer', offer)
    },

    setOfferAsRedeemed (token) {
      this.$store.commit('showLoading')
      let payload = {
        token: token.id,
        brandId: this.$store.state.userData.brandId,
        brandOfferId: this.brandOffer.id,
        offerId: this.brandOffer.offer.id
      }
      this.$http
        .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/offers/redeem`, payload)
        .then(
          function (response) {
            console.log(response)
            if (response.status === 200) {
              this.$router.push('/brand/dashboard')
              this.$store.commit('showMessage', { message: 'Transaction<br /> Successful!' })
            } else {
              this.$notify({ type: 'error', text: 'Internal server error!' })
              this.$store.commit('hideMessage')
            }
          },
          function (response) {
            this.$notify({ type: 'error', text: 'An error occurred while processing your transaction...', duration: -1 })
            this.$store.commit('hideMessage')
          }
        )
    }
  }
}

</script>

<style scoped>
.outer-box {
  padding: 15px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 25px;
}

.inner-box {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outer-box span {
  padding: 0 80px;
  white-space: nowrap;
  max-width: 100%;
  line-height: 30px;
  box-sizing: border-box;
  overflow: visible;
  display: inline-block;
}

.outer-box .title {
  font-weight: 500;
  font-size: 22px;
  color: #1f1f1f;
}

.outer-box .description {
  font-size: 17px;
  color: black;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.btn-positive {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
}

@media only screen and (max-width: 575px) {
  .outer-box {
    text-align: left;
  }

  .outer-box span {
    padding-left: 70px;
    padding-right: 0;
  }

  .outer-box .description {
    white-space: normal;
  }
}
</style>
